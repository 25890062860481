import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, useMediaQuery, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: '3rem',
	},
}));

export const CustomerLogos = ({ customerLogosArray, customerLogosHeader }) => {
	const sm = useMediaQuery('(max-width: 600px)');
	return (
		<div style={{ padding: '3rem 0 0' }}>
			{!!customerLogosHeader ? (
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'
					style={{ padding: '2rem 0' }}>
					<Grid item xs={12}>
						<Typography
							variant='h2'
							color='primary'
							style={{
								fontWeight: 600,
								textAlign: 'center',
							}}>
							{customerLogosHeader}
						</Typography>
					</Grid>
				</Grid>
			) : null}
			<Grid
				// style={{
				// 	padding: '1rem 0',
				// 	border: '1px solid #fffff',
				// 	boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)',
				// 	borderRadius: '8px',
				// }}
				container
				direction='row'
				justifyContent='space-around'
				alignItems='center'
				spacing={2}>
				{customerLogosArray.map((customerLogo, index) => (
					<Grid
						container
						direction='row'
						justifyContent='center'
						item
						xs={12}
						sm={6}
						md={3}
						key={index}>
						<GatsbyImage
							image={customerLogo.logo?.asset.gatsbyImageData}
							alt='customer logos'
							//THIS IS WHERE THE IMAGES ARE TOO BIG
							// style={{ width: '425px' }}
						/>
					</Grid>
				))}
			</Grid>
		</div>
	);
};
