import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import {
	Grid,
	Typography,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	withStyles,
	IconButton,
	Box,
	makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
	title: {
		fontWeight: 700,
		color: theme.white,
		textAlign: 'center',
	},
	titleBackground: {
		background: theme.workwaveBlue,
		color: theme.white,
		padding: '2rem',
		borderRadius: '20px 20px 0px 0px',
	},
	featureDescription: {
		fontSize: '16px',
		fontWeight: 400,
		color: '#5A7184',
		lineHeight: '20px',
	},
	button: {
		background: '#002D5C',
		cursor: 'pointer',
		textDecoration: 'none',
		border: '1px solid #C3CAD9',
		padding: '2rem 0',
		opacity: 1,
		transition: 'all .5s',
		'&:hover': {
			opacity: 0.8,
		},
	},
}));

const StyledAccordion = withStyles({
	root: {
		border: '1px solid #C3CAD9',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
		'&$expanded': {
			margin: 'auto',
		},
	},
	expanded: {},
})(Accordion);

const StyledSummary = withStyles({
	root: {
		// backgroundColor: 'rgba(0, 0, 0, .03)',
		// border: '2px solid #C3CAD9',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56,
		},
	},
	content: {
		'&$expanded': {
			margin: '12px 0',
		},
	},
})(AccordionSummary);

const Details = ({ index, feature, handleChange, expanded, logos }) => {
	const classes = useStyles();

	// const 'RouteManager' = products?.cards[0]?.title;
	// const 'RouteManager 360' = products?.cards[1]?.title;
	// const 'RouteManager 360+'e = products?.cards[2]?.title;

	// checks how many product cards get passed from sanity for the purpose of the title of product
	// and checks for the combination of true/false values for their respective feature. Turns it into
	// string literals of the product names
	const associatedProduct = (feature, logos) => {
		if (logos.length === 1) {
      return (
        <>
          <Typography
            variant='h6'
            color='primary'
            style={{ fontWeight: 600, textAlign: 'center' }}>
            RouteManager
          </Typography>
        </>
      );
		} else if (logos.length === 2) {
			if (feature.colOne && feature.colTwo) {
        return (
          <>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager
            </Typography>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360
            </Typography>
          </>
        );
			} else if (feature.colOne && !feature.colTwo) {
				return (
          <>
            <Typography
              variant='h6'
              color='primary'
              style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager
            </Typography>
          </>
        );
			} else if (!feature.colOne && feature.colTwo) {
				return (
          <>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360
            </Typography>
          </>
        );
			} else {
				return null;
			}
		} else {
			if (feature.colOne && feature.colTwo && feature.colThree) {
				return (
          <>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager
            </Typography>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360
            </Typography>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360+
            </Typography>
          </>
        );
			} else if (feature.colOne && feature.colTwo && !feature.colThree) {
				return (
          <>
            <Typography
              variant='h6'
              color='primary'
              style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager
            </Typography>
            <Typography
              variant='h6'
              color='primary'
              style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360
            </Typography>
          </>
        );
			} else if (feature.colOne && !feature.colTwo && !feature.colThree) {
				return (
          <>
            <Typography
              variant='h6'
              color='primary'
              style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager
            </Typography>
          </>
        );
			} else if (!feature.colOne && feature.colTwo && feature.colThree) {
				return (
          <>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360
            </Typography>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360+
            </Typography>
          </>
        );
			} else if (feature.colOne && !feature.colTwo && feature.colThree) {
				return (
          <>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager
            </Typography>
          	<Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360+
            </Typography>
          </>
        );
			} else if (!feature.colOne && feature.colTwo && !feature.colThree) {
				return (
          <>
            <Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360
            </Typography>
          </>
        );
			} else if (!feature.colOne && !feature.colTwo && feature.colThree) {
				return (
          <>
            <Typography
				      variant='h6'
				      color='primary'
				      style={{ fontWeight: 600, textAlign: 'center' }}>
              RouteManager360+
            </Typography>
          </>
        );
			} else {
				return null;
			}
		}
	};
	return (
		<StyledAccordion
			square
			expanded={expanded === index}
			onChange={handleChange(index)}>
			<StyledSummary>
				<Typography variant='h6' color='primary'>
					{feature.featureTitle}
				</Typography>
				<IconButton
					aria-label='expand row'
					size='small'
					color='secondary'
					style={{ marginLeft: '.5rem' }}>
					{expanded === index ? <RemoveIcon /> : <AddIcon />}
				</IconButton>
			</StyledSummary>
			<AccordionDetails>
				<Grid container direction='row'>
					<Grid item>
						<Typography variant='body1' className={classes.featureDescription}>
							{feature.featureDescription}
						</Typography>
					</Grid>
					<Grid
						item
						container
						style={{ marginTop: '2rem' }}
						direction='column'
						justifyContent='center'
						alignItems='flex-start'>
						<Typography variant='body1' color='primary'>
              {String(feature.title).toLowerCase() === 'sms text messaging' ? 'Available with:' : 'Included with:' }
						</Typography>
						{associatedProduct(feature, logos)}
					</Grid>
				</Grid>
			</AccordionDetails>
		</StyledAccordion>
	);
};

export const MobileFeatures = ({
	features,
	comparisonHeader,
	logos,
	ctaLineTitle,
	ctaRows,
	associatedProducts,
	modal,
	headers,
}) => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(0);

	const handleChange = (panel) => (e, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<>
			<Box
				component='section'
				className={classes.titleBackground}
				style={{ marginTop: '2rem' }}>
				<Grid
					container
					direction='row'
					justifyContent='center'
					alignItems='center'>
					<Typography variant='h5' className={classes.title}>
						{comparisonHeader}
					</Typography>
				</Grid>
			</Box>
			{features.slice(0, 10).map((feature, index) => (
				<Details
					key={index}
					index={index}
					feature={feature}
					handleChange={handleChange}
					expanded={expanded}
					logos={logos}
				/>
			))}
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
				style={{ background: '#002D5C', padding: '.5rem 0' }}>
				<Typography
					variant='h5'
					style={{ color: '#FFF', fontWeight: 600, textAlign: 'center' }}>
					Ready to learn more?
				</Typography>
			</Grid>
			{/* <Grid container direction='row'>
				{logos.map((logo, index) => (
					<Grid
						item
						xs={logos.length === 1 ? 12 : logos.length === 2 ? 6 : 4}
						container
						key={index}
						justifyContent='center'
						style={{
							padding: '1rem 0',
							border: '1px solid #C3CAD9',
							borderBottom: 'none',
							borderRight:
								index !== logos.length - 1
									? '1px solid #C3CAD9'
									: '2px solid #C3CAD9',
							borderLeft:
								index !== 0 ? '1px solid #C3CAD9' : '2px solid #C3CAD9',
						}}>
            <GatsbyImage image={logo.asset?.gatsbyImageData} imgStyle={{ objectFit: 'contain' }} />
					</Grid>
				))}
			</Grid> */}
			<Grid container direction='row'>
				{/* {ctaRows.map((cta, index) => ( */}
				<Grid
					item
					xs={12}
					container
					component='a'
					href=''
					justifyContent='center'
					target='_blank' aria-label="Opens in a new tab"
					className={classes.button}
					style={{
						background: '#2a7abc',
						borderRight: '1px solid #C3CAD9',
						borderLeft: '1px solid #C3CAD9',
						borderRadius: '0px 0px 20px 20px',
					}}>
					<Typography
						variant='body1'
						style={{
							color: 'white',
							textTransform: 'uppercase',
							fontWeight: 600,
							textAlign: 'center',
						}}>
						CHAT WITH US TODAY
					</Typography>
				</Grid>
				{/* ))} */}
			</Grid>
		</>
	);
};
