import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Container, } from '@material-ui/core';
import { WhyRouteManagerCard } from './WhyRouteManagerCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,
		padding: '0px',
		borderRadius: '20px',
		minHeight: '210px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		position: 'relative',
		transition: 'transform 0.25s',
    [theme.breakpoints.down('md')]: {
			maxWidth: '500px',
		},
	},
  whyRMHeader: {
		fontWeight: 700,
		color: 'white',
		marginBottom: '4rem',
		textAlign: 'center',
    // [theme.breakpoints.down('md')]: {
    //   textAlign: 'left',
    // },
	},
}));

export const WhyRouteManagerCards = ({ header, cards }) => {
	const classes = useStyles();

	return (
    <>
      <Container>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Grid item xs={12} md={8}>
            <Typography variant='h2' className={classes.whyRMHeader}>
              {header}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='space-around'
          alignItems='center'
          spacing={3}>
          {cards.map((card, index) => (
            <WhyRouteManagerCard key={index} card={card} />
          ))}
        </Grid>
      </Container>
    </>
  );
};